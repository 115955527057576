exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-klimaticket-tracker-privacy-policy-de-js": () => import("./../../../src/pages/klimaticket-tracker/privacy-policy.de.js" /* webpackChunkName: "component---src-pages-klimaticket-tracker-privacy-policy-de-js" */),
  "component---src-pages-klimaticket-tracker-privacy-policy-en-js": () => import("./../../../src/pages/klimaticket-tracker/privacy-policy.en.js" /* webpackChunkName: "component---src-pages-klimaticket-tracker-privacy-policy-en-js" */),
  "component---src-pages-photo-histogram-game-privacy-policy-de-js": () => import("./../../../src/pages/photo-histogram-game/privacy-policy.de.js" /* webpackChunkName: "component---src-pages-photo-histogram-game-privacy-policy-de-js" */),
  "component---src-pages-photo-histogram-game-privacy-policy-en-js": () => import("./../../../src/pages/photo-histogram-game/privacy-policy.en.js" /* webpackChunkName: "component---src-pages-photo-histogram-game-privacy-policy-en-js" */),
  "component---src-pages-privacy-policy-de-js": () => import("./../../../src/pages/privacy-policy.de.js" /* webpackChunkName: "component---src-pages-privacy-policy-de-js" */),
  "component---src-pages-privacy-policy-en-js": () => import("./../../../src/pages/privacy-policy.en.js" /* webpackChunkName: "component---src-pages-privacy-policy-en-js" */),
  "component---src-pages-wave-budget-privacy-policy-de-js": () => import("./../../../src/pages/wave-budget/privacy-policy.de.js" /* webpackChunkName: "component---src-pages-wave-budget-privacy-policy-de-js" */),
  "component---src-pages-wave-budget-privacy-policy-en-js": () => import("./../../../src/pages/wave-budget/privacy-policy.en.js" /* webpackChunkName: "component---src-pages-wave-budget-privacy-policy-en-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-imprint-js": () => import("./../../../src/templates/imprint.js" /* webpackChunkName: "component---src-templates-imprint-js" */)
}

